import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import './styles.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Jumbotron = (props) => {
  const document = props.doc
  return (
    <Carousel autoPlay interval={5000} showStatus={false} infiniteLoop useKeyboardArrows showThumbs={false}>
      {
        document.jumbotron.map((galleryItem, i) => (
          <div className="gallery-item" key={i}>
            <div className="gallery-image" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${galleryItem.image.url}) center / cover` }}></div>
            <div className="gallery-heading">
              <h1>{galleryItem.title[0].text}</h1>
            </div>
          </div>
        ))
      }
    </Carousel>
  );
}

export default Jumbotron;
