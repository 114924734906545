import React from 'react'
import Helmet from 'react-helmet'
import FontAwesome from 'react-fontawesome'
import './index.scss'
import firebase from "firebase";
import Header from '../header'

const TemplateWrapper = ({ children }) => (
  <div className='templateWrapperContainer' >
    <Helmet
      title="ALLLCARE Building Services LTD"
      meta={[
        { name: 'description', content: 'Sample' },
        { name: 'keywords', content: 'sample, something' },
      ]}
    />
    <Header />
    <div
      style={{
        margin: '0 auto',
        paddingBottom: '35px',
      }}
    >
      {children}
    </div>
    <div
      style={{
        width: '100%',
        height: '25px',
        paddingTop: '10px',
        fontSize: '12px',
        backgroundColor: '#cccccc',
        textAlign: 'center',
        position: 'absolute',
        bottom: 0
      }}
    >
      Copyright ALLLCARE 2017 <FontAwesome name='copyright' />
    </div>
  </div>
)

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKreTnIn475gdFBj0xl38ON0WmiOJOEDs",
  authDomain: "alllcareltd.firebaseapp.com",
  databaseURL: "https://alllcareltd.firebaseio.com",
  projectId: "alllcareltd",
  storageBucket: "alllcareltd.appspot.com",
  messagingSenderId: "1001220897610",
  appId: "1:1001220897610:web:a77043371577bed7"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default TemplateWrapper
