import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import './styles.scss';

import Jumbotron from '../../components/jumbotron/'
import Content from '../content/'
import HomeSmallImage from '../../components/home-small-img/'

class HomeLayout extends Component {
  state = {
    doc: null,
  }

  componentWillMount() {
    const apiEndpoint = 'https://alllcare.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api.query(
        Prismic.Predicates.at('document.type', 'home')
      ).then(response => {
        if (response) {
          this.setState({ doc: response.results[0] });
        }
      });
    });
  }

  render() {
    if (this.state.doc) {
      const document = this.state.doc.data;
      return (
        <div className='container'>
          <Jumbotron
            doc={document}
          />
          <Content>
            <div className='homeImgContainer'>
              {document.body[0].items.map((doc, i) => {
                return (
                  <HomeSmallImage
                    key={i}
                    img={doc.image.url}
                    text={doc.text}
                  />
                )
              })}
            </div>
          </Content>
        </div>
      );
    }
    return (
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    )
  }
}

export default HomeLayout;
