import React from 'react'
import './style.scss'

const HomeSmallImage = (props) => {
  return (
    <div className='homeSmallImg' >
      <div className='smallBgImg' style={{ backgroundImage: `url(${props.img})`,}}></div>
      <p>{props.text}</p>
    </div>
  )
}

export default HomeSmallImage
