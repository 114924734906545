import React from 'react'
import './styles.scss'

const Content = ({ children }) =>
  <div className='contentContainer' >
    {children}
  </div>


export default Content
