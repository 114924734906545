import React, { Component } from 'react'
import Link from 'gatsby-link'
import FontAwesome from 'react-fontawesome'
import './styles.scss'

import Logo from '../../assets/Logo.svg'
import Typograpghy from '../../assets/LogoTypograpghy.svg'

class Header extends Component {
  state = {
    menuToggle: false,
  }

  menuToggle(e) {
    e.preventDefault();
    this.setState({
      menuToggle: !this.state.menuToggle
    })
  }

  render() {
    return (
      <div className='headerContainer'>
        <div className='header'>
          <div className='mobileHeader'>
            <Link to="/" className='logoButton' >
              <img src={Logo} className='icon' alt="logo"/>
              <img src={Typograpghy} className='typography' alt="typography"/>
            </Link>
            <a className='menuToggle' onClick={(e) => {this.menuToggle(e)}} href="/">
              {this.state.menuToggle === true ?
                <FontAwesome name='times' size='2x' style={{ color: 'white'}} />
              :
                <FontAwesome name='bars' size='2x' style={{ color: 'white' }} />
              }
            </a>
          </div>
          <div id='nav' className={(this.state.menuToggle === true ? 'navBar' : 'navBar navBarHide')}>
            <Link to="/" className="navLink" activeClassName="activeTab" onClick={() => (this.menuToggle())}>Home</Link>
            <Link to="/about-us/" className="navLink" activeClassName="activeTab" onClick={() => (this.menuToggle())} >About Us</Link>
            <Link to="/services/" className="navLink" activeClassName="activeTab" onClick={() => (this.menuToggle())} >Services</Link>
            <Link to="/careers/" className="navLink" activeClassName="activeTab" onClick={() => (this.menuToggle())} >Careers</Link>
            <Link to="/contact/" className="navLink" activeClassName="activeTab" onClick={() => (this.menuToggle())} >Contact Us</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Header
