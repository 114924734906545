import React, { Component } from 'react'
import Layout from "../components/layouts"

import './styles.scss'

import HomeLayout from '../containers/home-layout/'

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <HomeLayout />
      </Layout>
    );
  }
}

export default IndexPage;
